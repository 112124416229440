<template>
  <div class="tag-search-select">
    <el-select
      v-model="observeModel"
      :multiple="multiple"
      filterable
      remote
      clearable
      reserve-keyword
      collapse-tags
      placeholder="搜尋會員標籤"
      no-match-text="無匹配項目"
      no-data-text="暫無數據"
      :remote-method="remoteSearch"
      :loading="loading"
      popper-class="tag-search-options"
      @clear="$emit('clear')"
      @visible-change="showAdvSelect = true"
    >
      <el-option
        v-for="item in tagList"
        :key="item.id"
        :label="item.name"
        :value="getValue(item)"
      />
    </el-select>
    <MemberTagAdvanceSelect
      :modelValue="observeAdvModelValue"
      :max="multiple ? 0 : 1"
      :show.sync="showAdvSelect"
      :options="tagList"
      @update:modelValue="onUpdateModelValue"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'

export default defineComponent({
  name: 'TagSearch',
  components: { MemberTagAdvanceSelect },
  props: {
    model: { type: [Array, String] },
    multiple: { type: Boolean },
    preserveNull: { type: Boolean },
    objKey: { type: String, default: 'id' },
    tags: { type: Array },
  },
  emits: ['update:model'],
  setup (props, { emit }) {
    const showAdvSelect = ref(false)

    const observeModel = computed({
      get: () => {
        return props.model
      },
      set: (value) => {
        emit('update:model', value)
      },

    })
    const onUpdateModelValue = (tags) => {
      observeAdvModelValue.value = tags
    }

    const observeAdvModelValue = computed({
      get: () => {
        if (props.multiple) return observeModel.value
        return observeModel.value ? [observeModel.value] : []
      },
      set: (tags) => {
        if (props.multiple) {
          observeModel.value = tags
        } else {
          observeModel.value = tags[0]
        }
      },
    })

    return {
      showAdvSelect,
      observeModel,
      onUpdateModelValue,
      observeAdvModelValue,
    }
  },
  data: () => ({
    loading: false,
    tagList: [],
  }),
  computed: {
    filterTags () {
      if (this.preserveNull) return this.tags
      return this.tags.filter(item => !item.isNullMember)
    },
  },
  watch: {
    filterTags: {
      handler () {
        this.tagList = [...this.filterTags]
      },
      immediate: true,
    },
  },
  methods: {
    getValue (item) {
      if (this.objKey) return item[this.objKey]
      else return item
    },
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.tagList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getTags(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getTags (query = undefined) {
      this.tagList = query ? this.filterTags.filter(i => i.name.includes(query)) : [...this.filterTags]
    },
  },
})
</script>

<style>
.tag-search-options {
  @apply hidden;
}
</style>
<style scoped lang="scss">

.tag-search-select {
  :deep(.el-tag){
    @apply max-w-[200px];
  }
}
</style>
