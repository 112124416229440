import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/pushMessage`
}

const group = (shopId) => {
  return `/${shopId}/groupPushMessage`
}

//= > 預估發送總人數 (無條件)
export const Estimate = async ({
  shopId,
  object,
  conditions,
}) => {
  const res = await admin({
    method: 'post',
    url: `${group(shopId)}/estimate`,
    data: {
      object,
      conditions,
    },
  })

  return res.data.count
}

//= > 預估發送總人數 (service)
export const EstimateDetail = async ({
  shopId,
  id,
  conditions,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}${id}/estimateDetail`,
    data: {
      conditions,
    },
  })

  return res.data
}

//= > 立即發送
export const ImmediateMessage = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'post',
    url: `${group(shopId)}/${id}/sent`,
  })

  return res.data
}

//= > 創建推播
export const CreatePushMessage = async ({
  shopId,
  action,
  setting,
  couponId,
  messages,
  expectSendAt,
  conditions,
}) => {
  const res = await admin({
    method: 'post',
    url: `${group(shopId)}`,
    data: {
      action,
      setting,
      couponId,
      messages,
      expectSendAt,
      conditions,
    },
  })

  return res.data
}

//= > 取得推播訊息
export const GetPushMessage = async ({
  shopId,
  start,
  limit,
  status,
  action,
}) => {
  const res = await admin({
    method: 'get',
    url: `${group(shopId)}`,
    params: {
      action,
      start,
      limit,
      status,
    },
  })

  return res.data
}

//= > 取得推播總數
export const GetPushMessageCount = async ({
  shopId,
  status,
  action,
}) => {
  const res = await admin({
    method: 'get',
    url: `${group(shopId)}/count`,
    params: {
      status,
      action,
    },
  })

  return res.data.count
}

//= > 查找指定推播
export const FindPushMessage = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `${group(shopId)}/${id}`,
  })

  return res
}

//= > 取消推播總數
export const CancelPushMessage = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'put',
    url: `${group(shopId)}/${id}/cancel`,
  })

  return res.data
}
